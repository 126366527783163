import axios from "axios";

/**
 * Axios Base URL
 * Configuracion de axios
 */
const rastreoApi = axios.create({
  baseURL: "https://sistema.globalpaq.mx/api/v2/public/rastreo",
  // baseURL: "http://sistema.test/api/v2/public/rastreo",
});

export default rastreoApi;

import { ref } from 'vue';
import Swal from 'sweetalert2';
import moment from 'moment';

import rastreoApi from '../api/rastreoApi';

/**
 * useRastreo
 * logica para rastrear
 * @returns
 */
const useRastreo = () => {
	const tracking = ref('');
	const rastreoInfo = ref(null);
	const historial = ref(true);
	const paquete = ref(false);
	const paqueteriaV = ref('');

	/**
	 * calculaPaqueteria
	 * obtiene a que paqueteria pertenece el tracking dependiendo de la longitud
	 * @param {string} tracking
	 * @returns string
	 */
	const calculaPaqueteria = (tracking) => {
		if (tracking.length == 10) return 'dhl';
		if (tracking.length == 22) return 'estafeta';
		if (tracking.length == 8) return 'redpack';
		if (tracking.length == 16) return 'globalpaq';
		if (
			tracking.length == 12 ||
			tracking.length == 11 ||
			tracking.length == 13
		)
			return 'paquetexpress';

		return '';
	};

	/**
	 * handleFedex
	 * formatea la respuesta de fedex para devolverla a la vista
	 * @param {Object} resp
	 * @returns
	 */
	const handleFedex = (resp) => {
		if (resp.error) {
			Swal.fire({
				icon: 'error',
				title: 'Opss',
				text:
					'Ocurrio un error al rastrear su guia ' +
						resp.data?.LocalizedMessage
						? resp.data?.LocalizedMessage
						: resp.data?.Notification?.Severity,
			});
			return null;
		}

		const data = resp.data

		// let events = null;
		// if (data.Events) {
		// 	events =
		// 		data?.Events?.length > 1
		// 			? data.Events.sort(
		// 				(a, b) =>
		// 					moment(a.Timestamp).unix() -
		// 					moment(b.Timestamp).unix()
		// 			)
		// 				.reverse()
		// 				.map((event) => ({
		// 					fecha: moment(event.Timestamp)
		// 						.locale('es-MX')
		// 						.format('LLL'),
		// 					descripcion: event.EventDescription,
		// 					ubicacion: `${event.Address.City || ''} ${event.Address.StateOrProvinceCode || ''
		// 						} ${event.Address.CountryCode || ''}`,
		// 				}))
		// 			: [
		// 				{
		// 					fecha: moment(data.Events.Timestamp)
		// 						.locale('es-MX')
		// 						.format('LLL'),
		// 					descripcion: data.Events.EventDescription,
		// 					ubicacion: `${data.Events.Address.City || ''} ${data.Events.Address.StateOrProvinceCode ||
		// 						''
		// 						} ${data.Events.Address.CountryCode || ''}`,
		// 				},
		// 			];
		// } else {
		// 	events = [
		// 		{
		// 			fecha: '',
		// 			descripcion: '',
		// 			ubicacion: '',
		// 		},
		// 	];
		// }
		// return {
		// 	tracking: data.TrackingNumber,
		// 	paqueteria: 'Fedex',
		// 	desde: `${data.ShipperAddress?.City || ''} ${data.ShipperAddress?.StateOrProvinceCode || ''
		// 		} ${data.ShipperAddress?.CountryCode || ''}`,
		// 	para: `${data.DestinationAddress?.City || ''} ${data.DestinationAddress?.StateOrProvinceCode || ''
		// 		} ${data.DestinationAddress?.CountryCode || ''}`,
		// 	servicio: data?.Service?.Description || '',
		// 	paquete: {
		// 		alto: data.PackageDimensions?.Height || null,
		// 		ancho: data.PackageDimensions?.Width || null,
		// 		largo: data.PackageDimensions?.Length || null,
		// 		unidadMedidas: data.PackageDimensions?.Units || null,
		// 		peso: data.PackageWeight?.Value || null,
		// 		unidadPeso: data.PackageWeight?.Units || null,
		// 		embalaje: data.Packaging,
		// 		piezas: data.PackageCount,
		// 	},
		// 	eventos: events,
		// };

		return resp.data;
	};

	/**
	 * handleDhl
	 * formatea la respuesta de dhl para devolverla a la vista
	 * @param {Object} resp
	 * @returns
	 */
	const handleDhl = (resp) => {
		if (resp.error || resp.data.AWBInfo.Status.ActionStatus != 'success') {
			Swal.fire({
				icon: 'error',
				title: 'Opss',
				text:
					'Ocurrio un error al rastrear su guia ' +
					resp.data.AWBInfo.Status.ActionStatus || '',
			});

			return null;
		}

		// const data = resp.data;
		const shipmentInfo = resp.data.AWBInfo.ShipmentInfo;

		return {
			tracking: resp.data.AWBInfo.AWBNumber,
			paqueteria: 'DHL',
			desde: `${shipmentInfo.Shipper.City} ${shipmentInfo.Shipper.DivisionCode} ${shipmentInfo.Shipper.CountryCode}`,
			para: `${shipmentInfo.Consignee.City} ${shipmentInfo.Consignee.DivisionCode} ${shipmentInfo.Consignee.CountryCode}`,
			servicio: shipmentInfo.GlobalProductCode,
			paquete: {
				alto: null,
				ancho: null,
				largo: null,
				unidadMedidas: null,
				peso: shipmentInfo.Weight,
				unidadPeso: shipmentInfo.WeightUnit,
				embalaje: null,
				piezas: shipmentInfo.Pieces,
			},
			eventos: shipmentInfo.ShipmentEvent
				? shipmentInfo.ShipmentEvent.sort(
					(a, b) =>
						moment(`${a.Date}T${a.Time}`).unix() -
						moment(`${b.Date}T${b.Time}`).unix()
				)
					.reverse()
					.map((event) => ({
						fecha: moment(`${event.Date}T${event.Time}`)
							.locale('es-MX')
							.format('LLL'),
						descripcion: event.ServiceEvent.Description,
						ubicacion: `${event.ServiceArea.Description} ${event.ServiceArea.ServiceAreaCode}`,
					}))
				: [
					{
						fecha: null,
						descripcion: null,
						ubicacion: null,
					},
				],
		};
	};

	/**
	 * handleEstafeta
	 * formatea la respuesta de estafeta para devolverla a la vista
	 * @param {Object} resp
	 * @returns
	 */
	const handleEstafeta = (resp) => {
		if (resp.error || resp.data.ExecuteQueryResult.errorCode != 0) {
			Swal.fire({
				icon: 'error',
				title: 'Opss',
				text:
					'Ocurrio un error al rastrear su guia ' +
					resp.data.AWBInfo.Status.ActionStatus || '',
			});

			return null;
		}

		const trackingData =
			resp.data.ExecuteQueryResult.trackingData.TrackingData;

		let events = [];

		events = trackingData.history.History.map((event) => ({
			fecha: event.eventDateTime,
			descripcion: event.eventDescriptionSPA,
			ubicacion: `${event.eventPlaceName} `,
		}));

		if (trackingData.statusENG == 'DELIVERED') {
			events.push({
				fecha: trackingData.deliveryData.deliveryDateTime,
				descripcion: trackingData.statusSPA,
				ubicacion: `${trackingData.deliveryData.destinationName} `,
			});
		}

		return {
			tracking: trackingData.waybill,
			paqueteria: 'Estafeta',
			desde: `${trackingData.pickupData.originName}`,
			para: `${trackingData.deliveryData.destinationName}`,
			servicio: trackingData.serviceDescriptionSPA,
			paquete: {
				alto: null,
				ancho: null,
				largo: null,
				unidadMedidas: null,
				peso: null,
				unidadPeso: null,
				embalaje: trackingData.packageType,
				piezas: null,
			},
			eventos: events.reverse(),
		};
	};

	/**
	 * handleRedpack
	 * formatea la respuesta de redpack para devolverla a la vista
	 * @param {Object} resp
	 * @returns
	 */
	const handleRedpack = (resp) => {
		if (resp.error || resp.data == null) {
			Swal.fire({
				icon: 'error',
				title: 'Opss',
				text:
					'Ocurrio un error al rastrear su guia ' +
					resp.data.AWBInfo.Status.ActionStatus || '',
			});

			return null;
		}

		const data = resp.data;

		return {
			tracking: null,
			paqueteria: 'Redpack',
			desde: null,
			para: null,
			servicio: null,
			paquete: {
				alto: data.alto,
				ancho: data.ancho,
				largo: data.largo,
				unidadMedidas: null,
				peso: data.peso,
				unidadPeso: null,
				embalaje: null,
				piezas: null,
			},
			eventos: data.detallesRastreo
				.sort(
					(a, b) =>
						moment(a.fechaEvento).unix() -
						moment(b.fechaEvento).unix()
				)
				.reverse()
				.map((event) => ({
					fecha: moment(event.fechaEvento)
						.locale('es-MX')
						.format('LLL'),
					descripcion: event.evento,
					ubicacion: `${event.localizacion} `,
				})),
		};
	};

	/**
	 * handleGlobalpaq
	 * formatea la respuesta de globalpaq para devolverla a la vista
	 * @param {Object} resp
	 * @returns
	 */
	const handleGlobalpaq = (resp) => {
		if (resp.error || resp == null) {
			Swal.fire({
				icon: 'error',
				title: 'Opss',
				text: 'Ocurrio un error al rastrear su guia ',
			});

			return null;
		}

		const data = resp.data;

		let eventos = data.eventos
			.sort((a, b) => moment(a.fecha).unix() - moment(b.fecha).unix())
			.reverse()
			.map((event) => {
				let ubicacion = '';
				if (event.id_status == 2) {
					if (event.fecha_recoleccion != null) {
						ubicacion = `Para el día ${moment(
							event.fecha_recoleccion
						)
							.locale('es-MX')
							.format('LL')}`;
					} else {
						ubicacion = '';
					}
				} else {
					ubicacion = event.comentario;
				}
				return {
					id_status: event.id_status,
					fecha: moment(event.fecha).locale('es-MX').format('LLL'),
					descripcion: event.nombre,
					ubicacion: ubicacion,
				};
			});

		let ultimo_evento;
		if (eventos[0].id_status == 2 && data.ultimo_evento.id_status == 2) {
			ultimo_evento = {
				id_status: data.ultimo_evento.id_status,
				fecha: moment(data.ultimo_evento.fecha)
					.locale('es-MX')
					.format('LL'),
				estatus: 'Recolección programada para el día:',
			};
		} else {
			ultimo_evento = {
				id_status: eventos[0].id_status,
				fecha: eventos[0].fecha,
				estatus: eventos[0].descripcion,
			};
		}
		return {
			tracking: data.tracking,
			paqueteria: 'Globalpaq',
			desde: `${data.remitente.ciudad} ${data.remitente.estado}`,
			para: `${data.destinatario.ciudad} ${data.destinatario.estado}`,
			servicio: data.tipoGuia,
			paquete: {
				alto: data.alto_real,
				ancho: data.ancho_real,
				largo: data.largo_real,
				unidadMedidas: null,
				peso: data.peso_real,
				unidadPeso: null,
				embalaje: null,
				piezas: null,
			},
			eventos: eventos,
			ultimo_evento: ultimo_evento,
		};
	};

	/**
	 * handlePaquetexp
	 * formatea la respuesta de paquetexpress para devolverla a la vista
	 * @param {Object} resp
	 * @returns
	 */
	const handlePaquetexp = (resp) => {
		if (resp.error) {
			Swal.fire({
				icon: 'error',
				title: 'Opss',
				text: 'Ocurrio un error al rastrear su guia',
			});

			return null;
		}

		const data = resp.data.reverse();
		const info = data[0];

		return {
			tracking: info.rastreo,
			paqueteria: 'Paquetexpress',
			desde: data[data.length - 1].ciudadEvento,
			para: info.ciudadDestino,
			servicio: null,
			paquete: {
				alto: null,
				ancho: null,
				largo: null,
				unidadMedidas: null,
				peso: null,
				unidadPeso: null,
				embalaje: null,
				piezas: null,
			},
			eventos: data.map((event) => ({
				fecha: `${event.fecha} ${event.hora}`,
				descripcion: event.status,
				ubicacion: `${event.sucursal}`,
			})),
		};
	};

	/**
	 * rastrear
	 * realiza la peticion a la API y manda la respuesta al handler correspondiente para su uso
	 * @returns
	 */
	const rastrear = async () => {
		let track = tracking.value.toString();
		rastreoInfo.value = null;

		Swal.showLoading();

		let paqueteria = calculaPaqueteria(track);

		if (paqueteria == '') {
			Swal.fire({
				icon: 'info',
				title: 'Tracking',
				text: 'No pudimos determinar a que paqueteria pertenece su tracking',
			});
			return;
		}
		const resp = await rastreoApi.get(`/${paqueteria}/${track}`);

		console.log(typeof resp.data.data);

		if (resp.status == 200) {
			let rastreoResp = {};

			if (paqueteria == 'fedex') {
				rastreoResp = handleFedex(resp.data);
			} else if (paqueteria == 'dhl') {
				rastreoResp = handleDhl(resp.data);
			} else if (paqueteria == 'estafeta') {
				rastreoResp = handleEstafeta(resp.data);
			} else if (paqueteria == 'redpack') {
				rastreoResp = handleRedpack(resp.data);
			} else if (paqueteria == 'globalpaq') {
				rastreoResp = handleGlobalpaq(resp.data);
			} else if (
				paqueteria == 'paquetexpress' &&
				resp.data.data.length > 0
			) {
				rastreoResp = handlePaquetexp(resp.data);
			} else if (
				paqueteria == 'paquetexpress' &&
				resp.data.data.length == 0
			) {
				paqueteria = 'fedex';
				const respFedex = await rastreoApi.get(
					`/${paqueteria}/${track}`
				);
				rastreoResp = handleFedex(respFedex.data);
			} else {
				rastreoResp = null;
			}

			if (rastreoResp != null) {
				rastreoInfo.value = rastreoResp;
				Swal.close();
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Ocurrio un error',
			});
		}
		paqueteriaV.value = paqueteria;
	};

	return {
		tracking,
		rastrear,
		rastreoInfo,
		setHistorialTrue: () => {
			historial.value = true;
			paquete.value = false;
		},
		setPaqueteTrue: () => {
			historial.value = false;
			paquete.value = true;
		},
		historial,
		paquete,
		paqueteriaV,
	};
};

export default useRastreo;
